<template>
  <div class="app" :class="theme">
  <SubscribeForm/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubscribeForm from "@/components/SubscribeForm";

export default {
  name: 'App',
  components: {
    SubscribeForm
  },
  data: () => {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters({
      theme: 'themeClass'
    }),

  }
}
</script>

<style>
input.error{
  color: #212529;
  background-color: #fff;
  border-color: #ff0000;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 54, 54, 0.25);
}

</style>
