<template>
  <div class="widget-wrap" >

    <div v-if="status === statuses.PROCESSING" class="spinner">
      <svg width='120px' height='120px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
           preserveAspectRatio="xMidYMid" class="uil-ripple">
        <rect x="0" y="0" width="100" height="100" fill="none" class="bk"></rect>
        <g>
          <animate attributeName="opacity" dur="2s" repeatCount="indefinite" begin="0s" keyTimes="0;0.33;1"
                   values="1;1;0"></animate>
          <circle cx="50" cy="50" r="40" stroke="#cec9c9" fill="none" stroke-width="6" stroke-linecap="round">
            <animate attributeName="r" dur="2s" repeatCount="indefinite" begin="0s" keyTimes="0;0.33;1"
                     values="0;22;44"></animate>
          </circle>
        </g>
        <g>
          <animate attributeName="opacity" dur="2s" repeatCount="indefinite" begin="1s" keyTimes="0;0.33;1"
                   values="1;1;0"></animate>
          <circle cx="50" cy="50" r="40" stroke="#3c302e" fill="none" stroke-width="6" stroke-linecap="round">
            <animate attributeName="r" dur="2s" repeatCount="indefinite" begin="1s" keyTimes="0;0.33;1"
                     values="0;22;44"></animate>
          </circle>
        </g>
      </svg>
    </div>

    <div v-if="status === statuses.ERROR" class="error">
      <h1>{{ ErrorText }}</h1>
    </div>

    <div v-if="status === statuses.SUCCESS" class="success">
      <h1>{{ SuccessText }}</h1>
    </div>

    <div v-if="status === statuses.NEW" class="form" :style="primaryStyle">
      <div class="form-title" v-bind:style="{ textAlign: TitleAlign, fontSize: TitleSize + 'px' }">{{ Title }}</div>
      <form class="row g-3" @submit="submitForm($event)" >
        <div v-if="fields.name" class="col-12">
          <input type="text" class="form-control" v-bind:class="{ error: errorsField.name }" v-model="name"
                v-bind:style="{ textAlign: FieldsAlign, fontSize: FieldsSize + 'px' }"
                :placeholder="NamePlaceholder">
        </div>
        <div v-if="fields.phone" class="col-12">
          <input type="text" class="form-control"  v-maska="PhoneMask" v-bind:class="{ error: errorsField.phone }" v-model="phone"
                v-bind:style="{ textAlign: FieldsAlign, fontSize: FieldsSize + 'px' }"
                :placeholder="PhonePlaceholder">
        </div>
        <div v-if="fields.email" class="col-12">
          <input type="email" class="form-control" v-bind:class="{ error: errorsField.email }" v-model="email"
                v-bind:style="{ textAlign: FieldsAlign, fontSize: FieldsSize + 'px' }"
                :placeholder="EmailPlaceholder">
        </div>
        <div class="col-12">
          <button type="submit" v-bind:style="{ textAlign: ButtonAlign, fontSize: ButtonSize + 'px', backgroundColor: ButtonColor }" class="btn w-100" >{{ ButtonText }}</button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { maska } from 'maska'

import {mapGetters} from 'vuex'
import axios from 'axios'
import { GEO_PHONES } from "@/geo_phones";

const NEW = 'new'
const PROCESSING = 'processing'
const ERROR = 'error'
const SUCCESS = 'success'

const BACKEND_URL = process.env.VUE_APP_API

export default {
  name: 'SubscribeForm',
  directives: { maska },
  methods: {

    changeFormStatus(status) {
      this.status = status
    },

    submitForm(event) {
      let formData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
      }

      let validator = this.fieldsValidator
      validator.registerRules({
        phone_validator: () => {
          return value => {
            //eslint-disable-next-line
            let digitsStr = value.replace(/[^0-9]+/g, '');
            let valid = GEO_PHONES[this.geo].regex.test(digitsStr)

            if (valid) {
              //eslint-disable-next-line
              value = "+" + digitsStr;
              return null;
            }
            return "INVALID PHONE"
          };
        }
      });
      let validData = validator.validate(formData)

      let formStatus = this.changeFormStatus
      if (validData) {

        let affiliateCode = this.affiliateCode
        let domain = window.location.protocol + '//' + window.location.host + window.location.pathname

        formStatus(PROCESSING)


        // REQUEST TO SERVER
        const params = new URLSearchParams();
        params.append('name', this.name);
        params.append('affiliate_code', affiliateCode);
        params.append('mobile_phone', this.phone);
        params.append('email', this.email);
        params.append('domain', domain);
        axios.post(BACKEND_URL, params)
            .then(function (response) {
              console.log(response.status);
              formStatus(SUCCESS)
              console.log(validData)
            })
            .catch(function (error) {
              console.log(error);
              formStatus(ERROR)
            })


      } else {
        let errors = validator.getErrors()
        for (const [key] of Object.entries(errors)) {
          this.errorsField[key] = true
        }
        console.log('errors', validator.getErrors());
      }

      event.preventDefault();
    },

  },

  data() {
    return {
      name: "",
      email: "",
      phone: "",
      status: NEW,
      statuses: {
        NEW,
        PROCESSING,
        ERROR,
        SUCCESS
      },
      errorsField: {
        name: false,
        email: false,
        phone: false
      }
    }
  },

  computed: {
    ...mapGetters({
      geo: 'geo',
      fields: 'fields',
      fieldsValidator: 'fieldsValidator',
      title: 'title',
      titleAlign: 'titleAlign',
      titleSize: 'titleSize',
      nameText: 'nameText',
      phoneText: 'phoneText',
      emailText: 'emailText',
      fieldsAlign: 'fieldsAlign',
      fieldsSize: 'fieldsSize',
      successText: 'successText',
      errorText: 'errorText',
      buttonText: 'buttonText',
      buttonAlign: 'buttonAlign',
      buttonSize: 'buttonSize',
      primaryColor: "primaryColor",
      buttonColor: "buttonColor",
      affiliateCode: "affiliateCode"
    }),
    Title() {
      return (this.title || undefined) ? this.title : this.$t('title')
    },
    TitleAlign() {
      return (this.titleAlign || undefined) ? this.titleAlign : "left"
    },
    TitleSize() {
      return (this.titleSize || undefined) ? this.titleSize : "24"
    },
    NamePlaceholder() {
      return (this.nameText || undefined) ? this.nameText : this.$t('name')
    },
    EmailPlaceholder() {
      return (this.emailText || undefined) ? this.emailText : this.$t('email')
    },
    PhoneMask() {
      return GEO_PHONES[this.geo].mask
    },
    PhonePlaceholder() {
      return (this.phoneText || undefined) ? this.phoneText : GEO_PHONES[this.geo].placeholder
    },
    FieldsAlign() {
      return (this.fieldsAlign || undefined) ? this.fieldsAlign : "left"
    },
    FieldsSize() {
      return (this.fieldsSize || undefined) ? this.fieldsSize : "16"
    },
    ButtonText() {
      return (this.buttonText || undefined) ? this.buttonText : this.$t('buttonText')
    },
    ButtonAlign() {
      return (this.buttonAlign || undefined) ? this.buttonAlign : "left"
    },
    ButtonSize() {
      return (this.buttonSize || undefined) ? this.buttonSize : "16"
    },
    SuccessText() {
      return (this.successText || undefined) ? this.successText : this.$t('successText')
    },
    ErrorText() {
      return (this.errorText || undefined) ? this.errorText : this.$t('errorText')
    },
    primaryStyle() {
      return (this.primaryColor || undefined) ? {backgroundColor: this.primaryColor} : {}
    },
    ButtonColor() {
      return (this.buttonColor || undefined) ? this.buttonColor : "#7faf2a"
    },
  },

  components: {},

  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-title{
  font-size: 24px;
  font-weight: 400;
  color: #212529 !important;
  text-shadow: none;
  margin: 0 0 15px;
}

.dark .form-title {
  color: #fff !important;
}

.form-control {
  color: #495057;
  padding: 10px 12px;
  background: #fff;
  font-size: 16px;
  line-height: 1.5;
}

.form-control:focus, button:focus {
  border-color: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #9b9b9b;
}

button {
  padding: 10px 12px;
  border: none;
  font-size: 16px;
  background: #7faf2a;
  background-size: 0 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: block;
  white-space: normal;
  width: 100%;
}

button:hover, button:active {
  border: none;
  background-size: 100% 100%;
  background-color: #7faf2a;
}

button:active:focus {
  box-shadow: none;
}
.widget-wrap {
  margin: 15px;
}
.widget-wrap .form{
  padding: 20px;
  max-width: 700px;
  margin: 15px auto;
  background: #efefef;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.dark .widget-wrap {
  background: #464646;
  color: #fff;
}

.dark .form-control {
  background: transparent;
  color: #fff;
}

.spinner {
  text-align: center;
}

.spinner svg {
  width: 75px;
  height: 75px;
}

.success h1, .error h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

form.row {
  margin-left: -15px;
  margin-right: -15px;
  width: auto;
}

.col-12 {
  margin-top: 15px;
  padding: 0 15px;
}

.dark .form-control::placeholder {
  color: #fff !important;
}

</style>
