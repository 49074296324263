const LANGUAGES = require('./languages')
const THEMES = ['light', 'dark']
import { createApp } from 'vue'
import App from './App.vue'
import Translations from "@/translate";
import Store from "@/store";
import LIVR from 'livr';
import { GEO_PHONES } from '@/geo_phones'

LIVR.Validator.defaultAutoTrim(true);

const configValidator = new LIVR.Validator({
    fieldCount: ['required', 'positive_integer', {max_number: 3}],
    title: ['string'],
    titleAlign: ['string'],
    titleSize: ['string'],
    lang:  ['required', 'string', {one_of: LANGUAGES}],
    geo:  ['required', 'string', {one_of: Object.keys(GEO_PHONES)}],
    theme: ['required', 'string', {one_of: THEMES}],
    nameText: ['string'],
    emailText: ['string'],
    phoneText: ['string'],
    fieldsAlign: ['string'],
    fieldsSize: ['string'],
    buttonText: ['string'],
    primaryColor: ['string'],
    buttonColor: ['string'],
    buttonAlign: ['string'],
    buttonSize: ['string'],
    successText: ['string'],
    errorText: ['string'],
    errorButtonText: ['string'],
    displayErrors: ['integer'],
    affiliateCode: ['required', 'positive_integer'],
});

const app = createApp(App)
const translations = Translations()
window.SalesdoublerSenderWidgetInit = (config, mount) => {
    //Validate Config
    let appConfig = configValidator.validate(config)
    if (appConfig) {
        Store.commit('setConfig', appConfig)


        app.use(Store)
        translations.global.locale = Store.getters.lang
        app.use(translations)


        app.mount(mount)


    } else {
        console.error('widget config validation error', configValidator.getErrors());
    }

}


window.SalesdoublerSenderWidgetConfigure = (config) => {
    //Validate Config
    let appConfig = configValidator.validate(config)
    if (appConfig) {
        Store.commit('setConfig', appConfig)
        translations.global.locale = Store.getters.lang

    } else {
        console.error('widget config validation error', configValidator.getErrors());
    }

}

