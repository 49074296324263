import { createStore } from 'vuex'
import LIVR from 'livr';
export const Store = createStore({
    state () {
        return {
            config: {}
        }
    },
    mutations: {
        setConfig (state, config) {
            state.config = config
        }
    },
    getters: {
        fields: (state) => {
            let fields = { phone: true, name: false, email: false}
            if(state.config.fieldCount > 1) {
                fields.name = true
            }
            if(state.config.fieldCount > 2) {
                fields.email = true
            }
            return fields
        },
        fieldsValidator: (state) => {
            let validator = {
                'phone': ['required', 'string', 'phone_validator']
            }
            if (state.config.fieldCount > 1) {
                validator['name'] = ['required', 'string', {max_length: 20}]
            }
            if (state.config.fieldCount > 2) {
                validator['email'] = ['required', 'email']
            }
            return new LIVR.Validator(validator)
        },
        geo: (state) => state.config.geo || null,
        title: (state) => state.config.title || null,
        titleAlign: (state) => state.config.titleAlign || null,
        titleSize: (state) => state.config.titleSize || null,
        nameText: (state) => state.config.nameText || null,
        phoneText: (state) => state.config.phoneText || null,
        emailText: (state) => state.config.emailText || null,
        fieldsAlign: (state) => state.config.fieldsAlign || null,
        fieldsSize: (state) => state.config.fieldsSize || null,
        buttonText: (state) => state.config.buttonText || null,
        buttonAlign: (state) => state.config.buttonAlign || null,
        buttonSize: (state) => state.config.buttonSize || null,
        successText: (state) => state.config.successText || null,
        errorText: (state) => state.config.errorText || null,
        primaryColor: (state) => state.config.primaryColor || null,
        buttonColor: (state) => state.config.buttonColor || null,
        lang: (state) => state.config.lang,
        themeClass (state) {
            let retval = {}
            let theme = state.config.theme;
            retval[theme] = true
            return retval
        },
        affiliateCode: (state) => state.config.affiliateCode || null,
    }
})

export default Store
